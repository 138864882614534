@keyframes slideInArticle {
    0% {
        margin-top: 60px;
    }
    100% {
        margin-top: 0px;
    }
}

figure {
    margin: 0;
}

.instagram-media {
    margin: 0 auto !important;
}

.content {
    z-index: 2;
    animation: 0.6s ease-out 0s 1 slideInArticle;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 36px;
    box-sizing: border-box;

    figcaption {
        margin-top: 1em;
        font-size: 80%;
        text-align: center;
    }
    img { max-width: 100%; }
}

.full-width {
    width: calc(100% + 360px);
    margin: 1.5em 0 3em;
    margin-left: -180px;
    text-align: center;
    max-width: calc( 100vw - 36px );
}
.content-width {
    margin: 1.5em 0 3em;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .full-width {
        width: calc( 100vw - 32px );
        margin-left: calc( 350px - 50vw - 18px ) !important;
    }
}



.gallery {
    display: flex;
    flex-direction: column;

    .gallery-image {
        flex: 1.5 1 0%;    
        img {
            display: block;
            margin: 0;
            width: 100%;
            height: 100%;
        }
        &:not(:first-of-type) { margin-left: .75em; }
    }

    .gallery-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        &:not(:first-of-type) { margin-top: .75em; }
    }
}

.single-image img:not(.lightense-open) { border-radius: 20px; }
.gallery-row:first-of-type .gallery-image:first-of-type img:not(.lightense-open) { border-top-left-radius: 20px; }
.gallery-row:first-of-type .gallery-image:last-of-type img:not(.lightense-open) { border-top-right-radius: 20px; }
.gallery-row:last-of-type .gallery-image:first-of-type img:not(.lightense-open) { border-bottom-left-radius: 20px; }
.gallery-row:last-of-type .gallery-image:last-of-type img:not(.lightense-open) { border-bottom-right-radius: 20px; }

.single-image img {
    width: 100%;
}

@media screen and (max-width: 700px) {
    .full-width {
        width: calc( 100% + 36px ) !important;
        margin-left: -18px !important;
    }
}

@media screen and ( max-width: 600px ) {
    .content {
        margin-top: -52px !important;
    }
}