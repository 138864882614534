.articles-hero {
    padding: 24px 36px;
    max-width: 1300px;
    margin: 0 auto;
}
.hero {
    vertical-align: middle;
    width: calc( 66% - 32px );
    margin-right: 32px;
    border-radius: 20px;
    height: 436px;
    min-height: 50px;
    display: inline-grid;
    grid-template-columns: 1;
    grid-auto-flow: row dense;
    overflow: hidden;
    .article-tile {
        height: 436px;
        top: 0;
        img, video {
            position: relative;
            height: auto;
            width: auto;
            min-width: 100%;
            opacity: 1;
            min-width: 100%;
            max-height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.article-list {
    width: 33%;
    display: inline-block;
    vertical-align: middle;

    .article-list-item {
        transition: 0.4s all;
        border-radius: 8px;
        padding: 12px;
        width: 100%;
        margin-left: -12px;
        margin-bottom: 4px;
        display: block;
        color: #000;
        text-decoration: none;
        &.active {
            background: #f3f3f3;
        }
        &:first-of-type {
            margin-top: 24px;
        }

        h4 {
            margin: 4px 0;
        }
    }
    .title {
        float: left;
        line-height: 1.8em;
    }
    .view-all {
        display: block;
        padding: 20px;
        text-align: center;
        text-decoration: none;
        color: #000;
    }
    .separator {
        clear: both;
        padding-bottom: 12px;
    }
}

.slogan {
    text-align: center;
    padding: 52px 0;
    font-size: 42px;
    margin-bottom: 42px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 140px;
        height: 12px;
        background: #333;
        bottom: 12px;
        left: calc( 50% - 70px );
        border-radius: 4px;
    }
}

.product-row {
    min-height: auto !important;
}

.embeded-logos {
    text-align: center;
    padding: 40px 0;
    picture {
        height: 100px;
        vertical-align: top;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    img {
        position: relative;
        max-height: 100px;
        max-width: 200px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.embeded-instagram  a {
    text-decoration: none;
}

.embeded-instagram {
    text-align: center;
}
.embeded-instagram picture {
    display: inline-block;
    width: 250px;
    height: 250px;
    text-align: center;
    background: #f3f3f3;
    border-radius: 4px;
    margin: 12px;
    overflow: hidden;
}
.embeded-instagram img {
    max-width: 250px;
}
.embeded-instagram a:nth-child(4n+1)::before {
    content: '';
    display: block;
}

.embeded-instagram__title a {
    text-decoration: none;
    color: #000;
}
.embeded-instagram__title svg {
    vertical-align: middle;
    height: 20px;
    margin-top: -3px;
    margin-right: 2px;
}

@media screen and ( min-width: 1400px ) {
    .articles-hero {
        padding-left: 80px;
        padding-right: 80px;
    }
}
@media screen and ( max-width: 775px ) {
    .articles-hero {
        padding-top: 0;
    }
    .article-list {
        width: 100%;
    }

    .slogan {
        text-align: center;
        padding: 24px 0;
        font-size: 24px;
        &::after { content: none; }
    }

    // Make the hero side scrollable
    .hero {
        margin: 0 -24px;
        width: calc( 100% + 48px );
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }
    .article-tile {
        width: 100%;
        display: inline-block;
        scroll-snap-align: start;
        flex-shrink: 0;
    }
}

@media screen and ( max-width: 500px ) {
    .embeded-logos {
        padding: 0;
        picture {
            display: block;
            width: 100%;
        }
    }
}

// Featured Artist

.featured-artist {
    padding: 24px;
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    > * {
        vertical-align: top;
    }
    > .information {
        display: inline-block;
        width: calc( 50% - 10px );
        h2 {
            padding-bottom: 20px;
        }
        picture {
            display: block;
            height: 760px;
            overflow: hidden;
            border-radius: 40px;
        }
        img {
            height: 100%;
            min-width: 100%;
        }
    }
    .product-grid {
        display: inline-grid;
        margin-left: 24px;
        width: calc( 50% - 24px );
    }
}

@media screen and (max-width: 1000px) {
    .featured-artist {
        .information {
            width: calc( 60% - 10px);
        }
        .product-grid {
            width: calc( 40% - 24px; );
            grid-template-columns: 100%;
            .product-tile + .product-tile ~ .product-tile {
                display: none;
            }
        }
    }
}
@media screen and ( max-width: 800px ) {
    .featured-artist {
        .product-grid {
            margin: 0;
            width: 100%;
            grid-template-columns: calc(50% - 18px) calc(50% - 18px);
        }
        .information {
            width: 100%;
        }
    }
}