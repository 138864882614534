.animate-all {
    transition: 0.4s all;
}

.floating-box {
    z-index: 2;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.50);
}

.unselectable {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

svg.icon {
    display: inline-block;
    vertical-align: middle;
}

nav {
    position: relative;
    box-sizing: border-box;
    margin: 36px;
    & > * {
        display: inline-block;
        vertical-align: top;
    }

    *:not(a):not(input) {
        @extend .unselectable;   
    }
    
    a {
        text-decoration: none;
        font-size: 14px;
        color: #1A1A1A;
    }
}
.logo {
    margin-right: 12px;
    img { width: 132px }
}

ul.links {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        transition: 0.4s background;
        position: relative;
        display: inline;
        padding: 3px 10px;
        text-align: center;
        font-weight: 600;
        a {
            line-height: 1.8em;
        }
        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 20px;
            z-index: -1;
            border-radius: 20px;
            padding: 3px 3px;
            top: 1px;
            left: 0px;
            transition: 0.4s all;
        }
        &.current-page::after {
            background: #f2f2f2;
        }
        &:hover::after {
            background: #f5f0f5;
        }
    }
    &:hover {
        li:not(:hover)::after {
            background: transparent !important;
        }
    }
}

div.search {
    z-index: 1;
    background: #ffffff;
    margin: -12px 0;
    padding: 12px;
    top: 0;
    right: 65px;
    position: absolute;
    white-space:nowrap;
    cursor: text;
    .icon {
        opacity: 0.68;
    }
    input {
        transition: 0.4s width;
        font-size: 14px;
        width: calc(100vw - 940px);
        max-width: 420px;
        // Set automatically to accomadate different items in the nav
        max-width: $searchBarMaxWidth;
        border: none;
        outline: none;
    }
    .results{
        opacity: 0;
        height: 0;
        width: 0;
        overflow: hidden;
        @extend .animate-all;
        a {
            display: block;
            margin: 0;
            padding: 12px 0;
            padding-left: 30px;
            position: relative;
            &::before {
                z-index: -1;
                top: 4px;
                left: 18px;
                content: '';
                position: absolute;
                display: block;
                width: calc( 100% - 38px );
                height: calc( 100% - 8px );
                border-radius: 4px;
                @extend .animate-all;
            }
            &:hover::before {
                background: #eee;
            }
        }
    }
    &:hover, &.active, &:focus {
        @extend .floating-box;
        transition: none;
        position: absolute;
        .results {
            transition: none;
            opacity: 1;
            height: auto;
            width: auto ;
        }
    }
}

.international {
    transition: 0.4s box-shadow;
    background: #ffffff;
    position: absolute;
    right: -12px;
    top: -12px;
    padding: 12px 12px;
    .current-country {
        opacity: 0.80;
        display: inline-block;
        text-decoration: none;
        color: #000;
        font-weight: 400;
        font-size: 14px;
    }
    .icon {
        opacity: 0.80;
        width: 18px;
    }
    ul {
        @extend .animate-all;
        opacity: 0;
        height: 0;
        width: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
    &:hover {
        @extend .floating-box;
        ul {
            opacity: 1;
            height: auto;
            width: auto;
            overflow: visible;

            display: block;
            list-style-type: none;
            li {
                margin: 4px -4px;
                width: calc( 100% + 8px );
                a {
                    text-decoration: none;
                    color: #000;
                    font-weight: 400;
                    font-size: 14px;
                    display: block;
                    border-radius: 4px;
                    padding: 4px;
                    &:hover {
                        background: #eee;
                    }
                }
            }
        }
    }
}

@media screen and ( min-width: 1400px ) {
    nav {
        margin: 36px 80px;
    }
}

@media screen and ( max-width: 1150px ) {
    nav div.search {
        &:hover, &.active, &:focus {
            input {
                width: 350px !important;
            }
        }
    }
}

@media screen and ( max-width: 1000px ) {
    nav .international {
        right: 34px;
    }
    nav .search {
        right: -24px;
        &:hover, &.active, :focus {
            right: -16px;
        }
        &:not(:hover):not(.active):not(:focus) {
            input {
                width: 0;
            }
        }
    }
}

@media screen and (max-width: 890px) {
    nav {
        margin: 24px;

        // To blur the sides of the links
        &::after {
            content: "";
            display: block;
            width: 40px;
            height: 22px;
            position: absolute;
            right: -24px;
            bottom: 0;
            background: rgba(255,255,255,0.00);
            background-image: linear-gradient(90deg, rgba(255,255,255,0.50) 0%, #FFFFFF 62%);
        }
    }
    div.search input {
        font-size: 16px !important;
    }
    .links {
        display: flex !important;
        flex-direction: row;
        padding-right: 0 !important;
        padding-bottom: 20px !important;

        clear: both;
        white-space: nowrap;
        overflow-y: visible;
        overflow-x: auto;
        position: relative;
        width: 100vw;
        top: 20px;
        left: -24px;
        li:not(.current-page):last-of-type { margin-right: 24px; }
        &:not( .current-page-highlighted ){
            li:first-of-type { margin-left: 24px; }
        }
        &.current-page-highlighted {
            & > li:not(.current-page) {
                order: 2;
            }
            & > li.current-page {
                margin-left: 24px;
                order: 1;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    div.search {
        top: -4px !important;
        &:hover, &.active {
            margin-left: -10px;
            input {
                // display: inline-block;
                max-width: calc(100vw - 80px) !important;
            }
        }
    }
}