@keyframes slideInCutoff {
    0% {
        margin-bottom: -40px;
    }
    100% {
        margin-bottom: 0px;
    }
}

.author {
    display: block;
    font-size: 14px;
    padding: 8px 0;
}
.time-since-published {
    font-size: 14px;
    margin-right: 12px;
}
.reading-time {
    opacity: 0.68;
    font-size: 14px;
    .icon {
        margin-top: 2px;
        margin-right: 4px;
        vertical-align: top;
    }
}

article header {
    // So the content can go above the header a bit
    z-index: -1;
}

header {
    position: relative;
    padding-left: 36px;
    padding-right: 36px;
    // ( 50vh - nav size - half the featured image )
    padding-top: calc( 50vh - 100px - 300px );
    
    .featured-image {
        display: inline-block;
        border-radius: 20px;
        margin: 0;
        width: 600px;
        height: 600px;
        img { width: 100%; }
        overflow: hidden;
        vertical-align: middle;
    }
    .information {
        // -> This is overwitten if there's a featured image present
        display: block;
        width: 100%;
        max-width: 1000px;
        margin: 80px auto;
        // <-

        box-sizing: border-box;
        text-decoration: none;
        color: #000;
        .tag {
            display: block;
            padding-bottom: 12px;
        }

        .author {
            display: block;
            font-size: 14px;
        }

        .description {
            line-height: 1.4em;
            display: inline-block;
            padding: 6px 0;
        }
    }
    .featured-image + .information {
        display: inline-block;
        vertical-align: middle;
        padding-left: 50px;
        padding-bottom: 50px;
        width: calc( 100% - 605px);
        margin: 0;
    }
    // Rounded cuttoff on the image
    &:not(.no-cutoff)::after {
        width: 500px;
        max-width: 42vw;
        min-width: 350px;
        height: 50px;
        
        content: '';
        background: #ffffff;
        border-top-left-radius: 20px;
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX( -99% );


        animation: 0.4s ease-out 0s 1 slideInCutoff;
    }

    &.search {
        max-width: 1000px;
        margin: 0 auto;
        .featured-image {
            width: 480px;
            height: 450px;
            picture {
                display: block;
                height: 100%;
                background: #fff;
                opacity: 0.9;
                text-align: center;
                img {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    width: auto;
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
        .information {
            width: calc( 100% - 485px);
        }
    }
}

@media screen and ( min-width: 1400px ) {
    header {
        padding-left: 80px;
        padding-right: 80px;
    }
}

$image-is-half-screen-width: (32px*2)+(600px*2);
@media screen and ( max-width: $image-is-half-screen-width ) {
    header {
        padding-top: calc( 50vh - 100px - 25vw );
        .featured-image {
            width: 50% !important;
            // height: auto;
            // height: 45vw !important;
            min-height: 43vw;
            img {
                height: auto;
                width: 100%;
                margin-bottom: -3px;
            }
        }
        .featured-image + .information {
            width: calc( 50% - 5px ) !important;
        }
    }
}

@media screen and ( max-width: 980px ) {
    header {
        padding-top: 24px;
    }
}

@media screen and ( max-width: 900px ) {
    header {
        .information {
            margin-top: 0;
            padding-left: 24px !important;
        }
        &::after {
            content: none;
        }
    }
}

@media screen and ( min-width: 600px ) {
    header:not(.search) .featured-image { background: none !important; }
}

@media screen and ( max-width: 600px ) {
    header:not(.search) {
        img {
            opacity: 0.9;
            // height: 100%;
            height: calc( 100vh - 200px );
            width: auto;
            position: relative;
            left: 50%;
            transform: translateX( -50% );
        }
    }
    header {
        padding: 0;
        position: relative;
        height: calc( 100% - 100px );
        .featured-image {
            border-radius: 0;
            width: 100% !important;
            height: 100%;
            min-height: 340px;
        }
        .information {
            padding: 0 38px !important;
        }
        .featured-image + .information {
            padding: 0 32px calc( 32px + 66px ) !important;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100% !important;
            * {
                color: #fff !important;
            }
            svg path {
                fill: #fff !important;
            }
            .reading-time { opacity: 1; }
            background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.40) 62%);
        }
        &:not(.no-cutoff)::after {
            z-index: 1;
            content: '' !important;
            height: 60px;
            max-width: none;
            border-top-right-radius: 20px;
            width: calc( 100vw - 24px ) !important;
            transform: translateX( -50% );
        }
    }
    header.search {
        .information {
            padding: 0 32px 32px !important;
        }
    }
}