.slider.screen-width {
    width: 100vw;
}
.slider {
    overflow: hidden;
    position: relative;
}

.slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    // Don't show scrollbar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { display: none; }
}

body.has-scrollbar {
    .slider.screen-width .next { margin-right: 12px }
    .slides { overflow: hidden; }
}

.slides > * {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    box-sizing: border-box;
}

.next, .prev {
    position: absolute;
    height: 100%;
    top: 0;
    padding: 36px;
    cursor: pointer;
}

.next span, .prev span {
    transition: 0.4s all;
    display: block;
    width: 18px;
    height: 18px;
    top: calc( 50% - 36px );
    position: absolute;
    border-width: 4px 4px 0 0;
    border-color: #ccc;
    border-style: solid;
}
.prev {
    left: 0;
    span { transform: rotatez(-135deg) translateX(50%); }
    &:hover { opacity: 0.8; }
}
.next {
    right: 0;
    span { transform: rotatez(45deg) translateX(-50%); }
    &:hover { opacity: 0.8; }
}

.product.slider {
    border-radius: 20px;
    &:not(.nozoom) { cursor: zoom-in; }
    .button {
        display: none;
    }
    picture {
        height: 327px;
        background: #ffffff;
        opacity: 0.90;
        overflow: hidden;
    }
    picture.lazy:not(.loaded) img {
        height: 100%;
    }
    .price {
        cursor: default;
        position: absolute;
        bottom: 20px;
        left: 20px;
        background: rgba(0, 0, 0, 0.7);
        color: #ffffff;
        font-size: 14px;
        font-weight: 900;
        padding: 6px 16px;
        border-radius: 20px;
        b { font-weight: 900; }
        s {
            color: red;
            span { color: #fff; }
        }
    }
    img {
        position: relative;
        top: 50%;
        transform: translatey(-50%);
        max-height: 100%;
        height: auto;
        max-width: calc( 100% - 8px );   
        display: block;
        margin: 0 auto;
    }
    .branded {
        display: none;
    }
}

.slider.header.content-width {
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
        font-size: 42px;
    }
    .featured-image {
        width: 450px;
        height: 450px;
    }
    .information {
        width: calc( 99% - 450px );
    }
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    &::before {
        content: '';
        width: 100vw;
        background: rgba(255,255,255,0.8);
        height: 100vh;
        display: block;
        z-index: -1;
    }
    & > .product.slider {
        cursor: zoom-out;
        position: absolute;
        top: 20px;
        left: 20px;
        width: calc( 100vw - 40px );
        height: calc( 100vh - 40px );
        picture {
            height: calc( 100vh - 40px);
            overflow: hidden;
        }
        picture.lazy:not(.loaded) img {
            height: 100%;
            filter: blur(10px);
        }
        img {
            position: relative;
            height: auto;
            max-height: 100%;
            top: 50%;
            transform: translatey(-50%);
        }
        .button {
            display: block;
            position: absolute;
            bottom: 20px;
            right: 20px;
            font-weight: 900;
        }
        .price {
            padding: 8px 12px;
            font-size: 1em !important;
        }
        .branded {
            position: absolute;
            display: block;
            top: 20px;
            right: 30px;
            opacity: 0.75;
            font-style: italic;
            font-weight: 900;
        }
    }
}

@media screen and (max-width: 600px) {
    .slider.content-width .featured-image {
        height: auto !important;
    }

    .fullscreen .product.slider .price,
    .next, .prev { display: none; }

    .fullscreen > .product.slider .button {
        width: calc( 100% - 60px );
        text-align: center
    }

    figure:not(.fullscreen) > .product.slider picture {
        height: auto;
        max-height: 90vh;
    }
    .product.slider img {
        max-width: 100%;
        position: relative;
        height: auto;
        top: 50%;
        transform: translatey(-50%);
    }

    figure:not(.fullscreen) .product.slider img {
        height: auto;
        width:  auto;
    }

    .branded {
        width: 100%;
        left: 0;
        text-align: center;
        top: 40px !important;
    }

    .dots {
        position: relative;
        top: 8px;
        text-align: center;
        box-sizing: border-box;
        .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            margin: 0 8px;
            background: #aaa;
            transition: 0.4s all;
            &.active {
                background: #333;
            }
        }
    }
    .fullscreen .product.slider + .dots {
        bottom: 110px;
        top: auto;
    }
}