@import url('/assets/fonts/inter.css');
* {
    font-family: -apple-system, 'Inter', sans-serif;
}

h1 {
    font-weight: 900;
    font-size: 54px;
    color: #333333;
    margin: 0;
}

h2 {
    font-weight: 900;
    font-size: 32px;
    color: #333333;
    margin: 0;
}

h3 {
    font-weight: 900;
    font-size: 24px;
    color: #000000;
    margin: 0;
}

p, .content li {
    font-size: 19px;
    color: #000000;
    line-height: 31px;
    margin-top: 12px;
    word-wrap: break-word;
}
// li {
// }

.tag {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: bold;
}

b, strong { font-weight: 600; }

a.plain {
    color: #000000;
    text-decoration: none;
}

@media screen and ( max-width: 1000px ) {
    h1 {
        font-size: 42px;
    }
}

@media screen and ( max-width: 800px ) {
    h1 {
        font-size: 30px !important;
    }
}