footer {
    position: relative;
    margin: 80px 80px 40px;
    
    a {
        text-decoration: none;
        font-size: 14px;
        color: #1A1A1A;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .links {
        text-align: center;
    }
    
    ul.social {
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        top: 0; 
        right: 0;
        svg { width: 20px; }
    }

    p {
        font-size: 14px;
        text-align: center;
        margin: 24px 0;
    }
}


@keyframes slideInCookies {
    0% {
        bottom: -200px;
    }
    100% {
        bottom: 12px;
    }
}

@keyframes blinker {
    50% {
        opacity: 0.2;
    }
}

#cookie {
    display: none;
    position: fixed;
    bottom: 12px;
    left: 50%;
    transform: translatex(-50%);
    padding: 12px 24px;
    box-sizing: border-box;
    z-index: 2;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.50);
    background: #fff;
    width: 710px;
    max-width: calc(100vw - 24px);
    animation: 0.4s ease-out 0s 1 slideInCookies;
    transition: all 0.6s ease-out;
    .seconds {
        opacity: 1;
        transition: all 0.4s;
    }
    &:hover .seconds {
        color: red;
        animation: blinker 2s linear infinite;
    }
    a, .close {
        white-space: nowrap;
        color: #000;
        text-decoration: none;
        border-bottom: solid 2px;
        padding-bottom: 2px;
        font-weight: 700;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
    p {
        margin-top: 4px;
        margin-bottom: 0;
        line-height: 1.6em;
        font-size: 14px;
        font-weight: 700;
    }
    & > span {
        font-size: 12px;
        display: block;
        margin: 8px 0;
    }
}

@media screen and (max-width: 890px) {
    footer {
        margin: 48px 24px;
        .links {
            padding-top: 24px;
        }
    }
}