@keyframes slideInGrid {
    0% {
        margin-top: -20px;
    }
    100% {
        margin-top: -50px;
    }
}

.article-grid {
    margin: -50px auto 0;
    min-height: 50px;
    animation: 0.6s ease-out 0s 1 slideInGrid;
    width: calc( 100% - 32px );
    max-width: 1100px;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 12px));
    grid-gap: 24px;
    grid-auto-flow: row dense;
}
.article-tile.featured {
    // grid-column: 2 / 4;
    grid-column: span 2;
    // &:nth-child(even) {
    //     grid-column: 1 / 3;
    // }
    img {
        position: absolute;
    }
    // Show the wide picture only
    picture+picture {
        display: none;
    }
}

.article-tile {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 200px;
    transition: 0.4s all;
    top: 0;

    img {
        margin-bottom: -3px;
        width: 100%;
        opacity: 0.8;
    }
    .information {
        z-index: 2;
        padding: 36px;
        box-sizing: border-box;

        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        * {
            color: #fff;
        }
        h3 {
            margin: 0;
            font-size: 31px;
            font-weight: 900;
        }
        svg path {
            fill: #fff !important;
        }
        .author {
            display: inline;
            margin-right: 4px;
        }
    }

    &::before {	
        content: '';	
        z-index: 1;	
        display: block;	
        width: 100%;	
        height: 100%;	
        position: absolute;	
        top: 0;	
        background-image: linear-gradient(-180deg, rgba(0,0,0,0) 1%, rgba(0,0,0,0.30) 70%);	
    }

    &:hover {
        top: -8px;
    }
}

@media screen and ( max-width: 980px ) {
    .article-grid {
        grid-template-columns: repeat(2, calc(50% - 24px));
        .article-tile.featured {
            grid-column: auto;
            picture:first-of-type {
                display: none;
            }
            picture + picture {
                display: block;
                img { position: static; }
            }
            .tag::before {
                content: "⭐️";
            }
        }
    }
}
@media screen and ( max-width: 660px ) {
    .article-grid {
        grid-template-columns: repeat(1, 100%);
    }
}